import React from "react"
import ReactDOM from "react-dom/client"
import "bootstrap/dist/css/bootstrap.min.css"
import './style.css'
import App from "./App.js"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);